import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"

const ContactThankYou = () => (
  <Layout>
    <SEO title="Kontakt potwierdzenie" />

    <GlobalTop title="Dziękujemy!" />

    <section className="page-404">
      <div className="container-fluid">
        <p>Skontaktujemy się z Tobą niebawem.</p>
        <Link to="/" className="btn-custom btn-custom--purple">
          Powrót do strony głównej
        </Link>
      </div>
    </section>
  </Layout>
)

export default ContactThankYou
